var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-2 py-4"},[_c('h1',{staticClass:"title"},[_vm._v("Prueba de HIS4")]),_c('b-message',{attrs:{"type":"is-info"}},[_vm._v(" Asigne los valores solicitados ")]),_c('div',{staticClass:"block"},[_c('div',{staticClass:"columns mt-2"},[_c('div',{staticClass:"column is-one-third"},[_c('b-field',{staticClass:"question",attrs:{"label":"N° Nódulos","type":_vm.$v.answerOne.minValue == false || _vm.$v.answerOne.maxValue == false
              ? 'is-danger'
              : 'is-ligth',"message":[
            {
              'El valor mínimo es 0.': !_vm.$v.answerOne.minValue,
            },
            {
              'El valor máximo es 999.': !_vm.$v.answerOne.maxValue,
            } ]}},[_c('b-numberinput',{attrs:{"size":"is-small","controls-position":"compact","controls-rounded":"","min":0,"max":999,"expanded":""},on:{"input":_vm.saveTermporalSulrvey},model:{value:(_vm.answerOne),callback:function ($$v) {_vm.answerOne=$$v},expression:"answerOne"}})],1)],1),_c('hr'),_c('div',{staticClass:"column is-one-third"},[_c('b-field',{staticClass:"question",attrs:{"label":"N° Absesos","type":_vm.$v.answerTwo.minValue == false || _vm.$v.answerTwo.maxValue == false
              ? 'is-danger'
              : 'is-ligth',"message":[
            {
              'El valor mínimo es 0.': !_vm.$v.answerTwo.minValue,
            },
            {
              'El valor máximo es 999.': !_vm.$v.answerTwo.maxValue,
            } ]}},[_c('b-numberinput',{attrs:{"size":"is-small","controls-position":"compact","controls-rounded":"","min":0,"max":999,"expanded":""},on:{"input":_vm.saveTermporalSulrvey},model:{value:(_vm.answerTwo),callback:function ($$v) {_vm.answerTwo=$$v},expression:"answerTwo"}})],1)],1),_c('hr'),_c('div',{staticClass:"column is-one-third"},[_c('b-field',{attrs:{"label":"N° Fístulas drenantes","type":_vm.$v.answerThree.minValue == false ||
            _vm.$v.answerThree.maxValue == false
              ? 'is-danger'
              : 'is-ligth',"message":[
            {
              'El valor mínimo es 0.': !_vm.$v.answerThree.minValue,
            },
            {
              'El valor máximo es 30.': !_vm.$v.answerThree.maxValue,
            } ]}},[_c('b-numberinput',{attrs:{"size":"is-small","controls-position":"compact","controls-rounded":"","min":0,"max":999,"expanded":""},on:{"input":_vm.saveTermporalSulrvey},model:{value:(_vm.answerThree),callback:function ($$v) {_vm.answerThree=$$v},expression:"answerThree"}})],1)],1)])]),_c('hr'),_c('b-message',{attrs:{"type":"is-info"}},[_c('strong',[_vm._v("Rango de resultados")]),_c('br'),_vm._v(" "+_vm._s(_vm.lowResult)+" "),_c('br'),_vm._v(" Moderado 4 – 10 "),_c('br'),_vm._v(" Severo >= 11 ")]),_c('div',{staticClass:"block has-text-centered title question"},[_vm._v(" Resultado: "+_vm._s(_vm.score)+" ("+_vm._s(_vm.scoreText)+") "),(_vm.isIncompleted)?_c('span',[_vm._v("(Incompleto)")]):_vm._e()]),(!_vm.queryMode)?_c('div',{staticClass:"buttons is-centered"},[_c('b-button',{staticClass:"clear-button",attrs:{"icon-right":"block-helper"},on:{"click":_vm.clear}},[_vm._v("Limpiar")]),_c('b-button',{staticClass:"save-button",attrs:{"icon-right":"check-bold"},on:{"click":_vm.validate}},[_vm._v("Guardar")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }