<template>
  <div class="px-2 py-4">
    <h1 class="title">Prueba de HIS4</h1>
    <b-message type="is-info">
      Asigne los valores solicitados
    </b-message>

    <div class="block">
      <div class="columns mt-2">
        <div class="column is-one-third">
          <b-field
            class="question"
            label="N° Nódulos"
            :type="
              $v.answerOne.minValue == false || $v.answerOne.maxValue == false
                ? 'is-danger'
                : 'is-ligth'
            "
            :message="[
              {
                'El valor mínimo es 0.': !$v.answerOne.minValue,
              },
              {
                'El valor máximo es 999.': !$v.answerOne.maxValue,
              },
            ]"
          >
            <b-numberinput
              size="is-small"
              controls-position="compact"
              controls-rounded
              :min="0"
              :max="999"
              expanded
              v-model="answerOne"
              @input="saveTermporalSulrvey"
            >
            </b-numberinput>
          </b-field>
        </div>
        <hr />
        <div class="column is-one-third">
          <b-field
            label="N° Absesos"
            class="question"
            :type="
              $v.answerTwo.minValue == false || $v.answerTwo.maxValue == false
                ? 'is-danger'
                : 'is-ligth'
            "
            :message="[
              {
                'El valor mínimo es 0.': !$v.answerTwo.minValue,
              },
              {
                'El valor máximo es 999.': !$v.answerTwo.maxValue,
              },
            ]"
          >
            <b-numberinput
              size="is-small"
              controls-position="compact"
              controls-rounded
              :min="0"
              :max="999"
              expanded
              v-model="answerTwo"
              @input="saveTermporalSulrvey"
            >
            </b-numberinput>
          </b-field>
        </div>
        <hr />
        <div class="column is-one-third">
          <b-field
            label="N° Fístulas drenantes"
            :type="
              $v.answerThree.minValue == false ||
              $v.answerThree.maxValue == false
                ? 'is-danger'
                : 'is-ligth'
            "
            :message="[
              {
                'El valor mínimo es 0.': !$v.answerThree.minValue,
              },
              {
                'El valor máximo es 30.': !$v.answerThree.maxValue,
              },
            ]"
          >
            <b-numberinput
              size="is-small"
              controls-position="compact"
              controls-rounded
              :min="0"
              :max="999"
              expanded
              v-model="answerThree"
              @input="saveTermporalSulrvey"
            >
            </b-numberinput>
          </b-field>
        </div>
      </div>
    </div>

    <hr />

    <b-message type="is-info">
      <strong>Rango de resultados</strong>
      <br />
      {{ lowResult }}
      <br />
      Moderado 4 – 10
      <br />
      Severo >= 11
    </b-message>

    <div class="block has-text-centered title question">
      Resultado: {{ score }} ({{ scoreText }})
      <span v-if="isIncompleted">(Incompleto)</span>
    </div>

    <div class="buttons is-centered" v-if="!queryMode">
      <b-button class="clear-button" icon-right="block-helper" @click="clear"
        >Limpiar</b-button
      >
      <b-button class="save-button" icon-right="check-bold" @click="validate"
        >Guardar</b-button
      >
    </div>
  </div>
</template>

<script>
import { HIS4 as questions } from "../../../data/questions";
import { minValue, maxValue } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      questions: questions,
      answerOne: 0,
      answerTwo: 0,
      answerThree: 0,
      lowResult: "Leve <= 3",
      restoreData: {},
      queryMode: false,
    };
  },
  async created() {
    if (!this.patient && !this.$route.params.surveyId) {
      this.$router.push({ name: "His4List" }).then(() => {
        this.showToast("No has seleccionado un paciente.", "is-warning", 3000);
      });
    }

    if (this.$route.params.surveyId) {
      this.queryMode = true;
      let data = await this.getSurveyData();
      this.setEvaluationAnswers(data);
    }

    if (this.$route.params.temporalData) {
      this.answerOne = this.$route.params.temporalData.answerOne;
      this.answerTwo = this.$route.params.temporalData.answerTwo;
      this.answerThree = this.$route.params.temporalData.answerThree;
    }

    this.$global.$on("restore", () => {
      this.restoreForm();
    });
  },
  computed: {
    score() {
      return (
        parseInt(this.answerOne || 0) * 1 +
          parseInt(this.answerTwo || 0) * 2 +
          parseInt(this.answerThree || 0) * 4 || 0
      );
    },
    scoreText() {
      let text = "";
      if (this.score <= 3) {
        text = "Leve";
      }

      if (this.score >= 4 && this.score <= 10) {
        text = "Moderado";
      }

      if (this.score > 10) {
        text = "Severo";
      }
      return text;
    },
    isIncompleted() {
      return (
        !this.answerOne < 0 || !this.answerTwo < 0 || !this.answerThree < 0
      );
    },
    userId: {
      get() {
        return this.$store.state.user.userData?.id;
      },
    },
    patient: {
      get() {
        return this.$store.state.patient.patientData;
      },
    },
  },
  methods: {
    cleanForms() {
      this.answerOne = 0;
      this.answerTwo = 0;
      this.answerThree = 0;
    },
    clear() {
      this.restoreData.answerOne = this.answerOne;
      this.restoreData.answerTwo = this.answerTwo;
      this.restoreData.answerThree = this.answerThree;

      this.cleanForms();
      this.clearTemporalSurveys();

      this.showUndoDialog("Se limpiaron los datos de  la encuesta HIS4.");
    },
    async save() {
      try {
        this.changeLoaderStatus(true);
        let data = {
          surveyCode: "HIS4",
          result: String(this.score),
          appreciation: this.scoreText,
          doctorId: String(this.userId),
          patientId: String(this.patient.document),
          detail: this.setAnswers(),
        };

        await this.$surveyService.saveSurvey(data);
        this.changeLoaderStatus(false);
        this.showToast(
          "Encuesta enviada satisfactoriamente.",
          "is-success",
          3000
        );
        this.scrollTop();
        this.cleanForms();
        this.clearTemporalSurveys();
      } catch (error) {
        this.changeLoaderStatus(false);
        this.showToast("Ha ocurrido un error inesperado.", "is-danger", 3000);
      }
    },
    setAnswers() {
      let question1 = {
        questionCode: questions.HIS4_NODULOS,
        answerCode: null,
        value: this.answerOne,
      };
      let question2 = {
        questionCode: questions.HIS4_ABSESOS,
        answerCode: null,
        value: this.answerTwo,
      };
      let question3 = {
        questionCode: questions.HIS4_FISTULAS,
        answerCode: null,
        value: this.answerThree,
      };

      return [question1, question2, question3];
    },
    validate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.save();
      } else {
        this.showToast(
          "Hay valores erróneos en las respuestas, por favor corrígelos.",
          "is-warning",
          3000
        );
      }
    },
    restoreForm() {
      this.answerOne = this.restoreData.answerOne;
      this.answerTwo = this.restoreData.answerTwo;
      this.answerThree = this.restoreData.answerThree;

      this.saveTermporalSulrvey();
    },
    saveTermporalSulrvey() {
      this.$session.set("temporalSurvey", {
        module: {
          name: "HIS4",
          route: "His4",
        },
        survey: {
          answerOne: this.answerOne,
          answerTwo: this.answerTwo,
          answerThree: this.answerThree,
        },
      });
    },
    setEvaluationAnswers(data) {
      data.evaluationDetails.forEach((survey) => {
        if (survey.question.code == "HIS4_NODULOS") {
          this.answerOne = survey.value;
        }

        if (survey.question.code == "HIS4_ABSESOS") {
          this.answerTwo = survey.value;
        }

        if (survey.question.code == "HIS4_FISTULAS") {
          this.answerThree = survey.value;
        }
      });
    },
  },
  validations: {
    answerOne: {
      minValue: minValue(0),
      maxValue: maxValue(999),
    },
    answerTwo: {
      minValue: minValue(0),
      maxValue: maxValue(999),
    },
    answerThree: {
      minValue: minValue(0),
      maxValue: maxValue(999),
    },
  },
};
</script>

<style scoped></style>
